import consumer from "./consumer"

function scrollToBottom() {
  $('.chat-history').animate({scrollTop: $('.chat-history')[0].scrollHeight}, 'fast');
}

$(function() {
  const chatChannel = consumer.subscriptions.create({ channel: "RakutenChatChannel", room: "rakuten_chat_" + $('#chat > .chat-history').data('rakuten_item_id'), rakuten_item_id: $('#chat > .chat-history').data('rakuten_item_id') }, {
    connected() {
      scrollToBottom();
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received: function(data) {
      let message = $($.parseHTML(data["message"]));
      let new_message = '';
      if (data['chat_user_name'] == $('input:hidden[name="my_name"]').val()) {
        new_message = message.addClass('my-message');
      } else {
        new_message = message.addClass('other-message');
      }

      let result = $('#chat > .chat-history > ul').append(new_message);

      scrollToBottom();

      return result;
    },

    speak: function(message, rakuten_item_id) {
      return this.perform('speak', {
        message: message,
        rakuten_item_id: rakuten_item_id
      });
    }
  });

  $(document).on('keypress', '[data-behavior~=rakuten_chat_speaker]', function(event) {
    if (event.keyCode === 13) {
      chatChannel.speak(event.target.value, $('#chat > .chat-history').data('rakuten_item_id'));
      event.target.value = '';
      return event.preventDefault();
    }
  });
});