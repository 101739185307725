window.read_message = function (id) {
  $.ajax({
    type: "delete",
    url: "/message/" + id,

    success: function (data) {
      $("#unread-" + id).hide();
      if (data.unread_count > 0) {
        $("#unread_count").text(data.unread_count);
      } else {
        $("#unread_count").hide();
      }
    },

    error: function (data) {
      console.log("error");
      console.log(data);
    }
  });
};
